import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import AnimateLink from '../animateLink';

const projectAssetsGallery = props => {
  const { title, visuels, downloadLink, isSlider, intl } = props;

  const [activeSlide, setActiveSlide] = useState(0);
  const [widthProgressBar, setWidthProgressBar] = useState(0);

  const slideLength = visuels?.length;

  // Custom Arrow
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`icon-arrow-right-circle custom-arrow ${className}`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <span
        className={`icon-arrow-left-circle custom-arrow ${className}`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  // Carousel Setting
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          variableWidth: false,
          adaptiveHeight: true,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => {
      setActiveSlide(current);
      setWidthProgressBar((current * 100) / (slideLength - 1));
    },
  };

  return (
    <>
      {!isSlider && (
        <section className="block-content assets is-fullwidth bkg-color1 has-download-link">
          <h3 className="title is-3 mb-6">{title}</h3>
          <div className="container grid mx-auto mb-0">
            {visuels?.map((visuel, i) => (
              <GatsbyImage
                key={i}
                as="figure"
                image={getImage(visuel)}
                alt={visuel.description || ''}
              />
            ))}
          </div>
          {downloadLink && (
            <AnimateLink
              url={downloadLink}
              externalLink={true}
              cssClassLink="link download"
              ariaLabel={`${intl.formatMessage({
                id: 'project.dl_assets',
              })}`}
            >
              <FormattedMessage id="project.dl_assets" />{' '}
              <span className="icon-download" aria-hidden={true}></span>
            </AnimateLink>
          )}
        </section>
      )}

      {isSlider && (
        <section className="block-content is-fullwidth has-download-link block-pictures">
          <h3 className="title is-3 mb-5">
            {title}
            <strong className="is-text-indication">
              {activeSlide + 1} / {slideLength}
            </strong>
          </h3>

          <div className="m-4 mb-6">
            <Slider {...settings}>
              {visuels?.map((visuel, i) => (
                <div key={i}>
                  <GatsbyImage
                    as="figure"
                    image={getImage(visuel)}
                    alt={visuel.description || ''}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="progress-slider">
            <div
              className="progress-slider-bar"
              style={{ width: widthProgressBar + '%' }}
            ></div>
          </div>

          {downloadLink && (
            <AnimateLink
              url={downloadLink}
              externalLink={true}
              cssClassLink="link download"
              ariaLabel={intl.formatMessage({
                id: 'project.dl',
              })}
            >
              <FormattedMessage id="project.dl" />{' '}
              <span className="icon-download" aria-hidden={true}></span>
            </AnimateLink>
          )}
        </section>
      )}
    </>
  );
};

export default injectIntl(projectAssetsGallery);
