import React, { Component } from 'react';

export default class progessBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    this.setState({
      scrolled: scrolled,
    });
  };

  render() {
    const progressBarStyle = {
      width: this.state.scrolled,
    };

    return (
      <div className="progress-container">
        <div className="progress-container">
          <div className="progress-bar" style={progressBarStyle}></div>
        </div>
      </div>
    );
  }
}
