import React from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

function ViewAllContributors(props) {
  const { contributors, isVisible, updateShowAllContributors, intl } = props;

  return (
    <section
      className={`block-view-all ${isVisible ? 'open' : ''}`}
      id="viewall"
      hidden={!isVisible}
    >
      <div className="p-5">
        <a
          tabIndex={isVisible ? '0' : '-1'}
          href="#link-viewall"
          className="close"
          onClick={e => updateShowAllContributors(e, false)}
          aria-label={intl.formatMessage({
            id: 'close_btn',
          })}
        >
          <FormattedMessage id="close_btn" />
          <span className="icon-close-circle"></span>
        </a>
        <h1 className="title is-1">
          <FormattedMessage id="project.contributors" />
        </h1>
        <ul className="columns is-flex-wrap-wrap list-people is-text-indication">
          {contributors?.map((contributor, i) => (
            <li key={i} className="mb-4 column is-one-third">
              <strong>{contributor.name}</strong>
              {contributor.job && contributor.job.label}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default injectIntl(ViewAllContributors);
