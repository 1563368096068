import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

import ViewAllContributors from './viewAllContributors';

class Contributors extends Component {
  // Animate Link
  animLinkIn = event => {
    event.target.classList.add('in');
  };

  animLinkOut = event => {
    event.target.classList.remove('in');
    event.target.classList.add('out');

    setTimeout(() => {
      event.target.classList.remove('out');
    }, 1000);
  };
  render() {
    const { contributors, viewAllOpen, intl } = this.props;
    var updateShowAllContributors = this.props.updateShowAllContributors;

    const smallContributorsList = contributors
      ?.slice(0, 4)
      ?.map((contributor, i) => (
        <p key={i} className="mb-4">
          <strong>{contributor.name}</strong>
          {contributor.job && contributor.job.label}
        </p>
      ));

    return (
      <>
        <article
          className="block-content contributors is-text-indication"
          aria-expanded={viewAllOpen}
          aria-haspopup={true}
        >
          <h4 className="title is-4 mb-4">
            <FormattedMessage id="project.contributors" />
          </h4>
          {smallContributorsList}

          <a
            href="#viewall"
            id="link-viewall"
            className="link view-all"
            onClick={e => updateShowAllContributors(e, true)}
            onMouseEnter={e => this.animLinkIn(e)}
            onMouseLeave={e => this.animLinkOut(e)}
            aria-label={intl.formatMessage({
              id: 'project.see_all_label',
            })}
          >
            <FormattedMessage id="project.see_all" />{' '}
            <span className="icon-arrow"></span>
          </a>
        </article>

        <ViewAllContributors
          contributors={contributors}
          isVisible={viewAllOpen}
          updateShowAllContributors={updateShowAllContributors}
        />
      </>
    );
  }
}

export default injectIntl(Contributors);
