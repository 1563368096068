import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import Slider from 'react-slick';

import AnimateLink from '../animateLink';

// Custom Arrow
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`icon-arrow-right-circle custom-arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`icon-arrow-left-circle custom-arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

class projectAssetsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      widthProgressBar: 0,
    };
  }

  render() {
    const data = this.props;
    const slideLength = data.visuels?.length;

    // Carousel Setting
    const settings = {
      className: 'slider variable-width',
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 760,
          settings: {
            variableWidth: false,
            adaptiveHeight: true,
          },
        },
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      afterChange: current =>
        this.setState({
          activeSlide: current,
          widthProgressBar: (current * 100) / (slideLength - 1),
        }),
    };

    return (
      <section className="block-content is-fullwidth has-download-link block-pictures">
        <h3 className="title is-3 mb-5">
          {data.title}
          <strong className="is-text-indication">
            {this.state.activeSlide + 1} / {slideLength}
          </strong>
        </h3>

        <div className="m-4 mb-6">
          <Slider {...settings}>
            {data.visuels?.map((visuel, i) => (
              <div key={i}>
                <GatsbyImage
                  as="figure"
                  image={getImage(visuel)}
                  alt={visuel.description || ''}
                />
                {/*<figure key={i}>
                  <img loading="lazy" src={getSrc(visuel)} alt={visuel.title} />
                </figure>*/}
              </div>
            ))}
          </Slider>
        </div>

        <div className="progress-slider">
          <div
            className="progress-slider-bar"
            style={{ width: this.state.widthProgressBar + '%' }}
          ></div>
        </div>

        {data.downloadLink && (
          <AnimateLink
            url={data.downloadLink}
            externalLink={true}
            cssClassLink="link download"
            ariaLabel={this.props.intl.formatMessage({
              id: 'project.dl',
            })}
          >
            <FormattedMessage id="project.dl" />{' '}
            <span className="icon-download" aria-hidden={true}></span>
          </AnimateLink>
        )}
      </section>
    );
  }
}

export default injectIntl(projectAssetsCarousel);
