import React from 'react';
import Capabilities from './capabilities';
import Contributors from './contributors';

function ProjectCommon({
  isMobile,
  capabilities,
  contributors,
  updateShowAllContributors,
  viewAllOpen,
}) {
  return (
    <>
      {!isMobile && (
        <div className="column is-3 border-left is-hidden-mobile">
          {/* Capabilities */}
          <Capabilities capabilities={capabilities} isMobile={isMobile} />

          {/* Contributors */}
          <Contributors
            contributors={contributors}
            updateShowAllContributors={updateShowAllContributors}
            viewAllOpen={viewAllOpen}
          />
        </div>
      )}

      {isMobile && (
        <div className="section columns is-mobile is-hidden-tablet">
          <div className="column border-top has-text-left py-5 mb-5 is-hidden-tablet">
            {/* Capabilities */}
            <Capabilities capabilities={capabilities} isMobile={isMobile} />

            {/* Contributors */}
            <Contributors
              contributors={contributors}
              updateShowAllContributors={updateShowAllContributors}
              viewAllOpen={viewAllOpen}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectCommon;
