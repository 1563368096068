import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';

import languageHelper from '../utils/helpers/languageHelper';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProgessBar from '../components/navigation/progessBar';
import HeroTitle from '../components/heroTitle';
import MainPlaceholderVideo from '../components/blockContent/mainPlaceholderVideo';
import ProjectCommon from '../components/project/projectCommon';
import FullwidthCarousel from '../components/blockContent/fullwidthCarousel';
import TextWithTitle from '../components/textWithTitle';
import FullwidthPlaceholderVideo from '../components/blockContent/fullwidthPlaceholderVideo';
import ProjectAssetsGallery from '../components/blockContent/projectAssetsGallery';
import ProjectAssetsCarousel from '../components/blockContent/projectAssetsCarousel';
import NavigationBar from '../components/navigation/navigationBar';
import VerbatimList from '../components/blockContent/verbatim/verbatimList';
import BackToTop from '../components/navigation/backToTop';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewAllOpen: false,
    };
  }

  // Toggle ViewAllContributors
  updateShowAllContributors = (event, viewAllOpen) => {
    var htmlElt = document.querySelector('html');
    if (htmlElt.classList.contains('is-clipped')) {
      document.querySelector('html').classList.remove('is-clipped');
    } else {
      document.querySelector('html').classList.add('is-clipped');
    }
    this.setState({ showViewAllOpen: viewAllOpen });
  };

  render() {
    const { data, intl } = this.props;
    const { project, locales, navigationPrevious, navigationNext } = data;
    const navigation = {
      previous: { ...navigationPrevious },
      next: { ...navigationNext },
    };

    const languageTo = languageHelper.getLanguageTo(locales.nodes);

    return (
      <>
        <Helmet>
          <body className="has-progress-bar" />
        </Helmet>
        <Layout
          headerCssClass="bkg-color3"
          mainCssClass={`case-page ${
            this.state.showViewAllOpen ? 'with-block-view-all' : ''
          }`}
          languageTo={languageTo}
        >
          <SEO
            title={project.seo?.metaTitle}
            titleOnglet={project.seo?.title}
            description={project.seo?.metaDescription}
            imageLink={project.seo?.socialImage?.file?.url}
            pageLink={project.seo?.slug}
          />
          <div className="project-sheet is-relative">
            {/* ProgessBar */}
            <ProgessBar />

            {/* HeroTitle */}
            <HeroTitle
              subtitle={project.client?.label}
              title={project.title}
              catchphrase={project.catchphrase?.childMarkdownRemark?.html}
            />

            {/* MainPlaceholderVideo */}
            <MainPlaceholderVideo media={project.MainPlaceholderVideo} />

            {/* WorkPresentation */}
            <section className="section columns is-variable is-8 is-justify-content-space-between">
              <div className="column is-7">
                {/* TextWithTitle - Challenge */}
                {project.challenge && (
                  <TextWithTitle
                    title={intl.formatMessage({ id: 'project.challenge' })}
                    text={project.challenge.childMarkdownRemark?.html}
                  />
                )}

                {/* TextWithTitle - Idea */}
                {project.idea && (
                  <TextWithTitle
                    title={intl.formatMessage({ id: 'project.idea' })}
                    text={project.idea.childMarkdownRemark?.html}
                  />
                )}
              </div>

              <ProjectCommon
                isMobile={false}
                capabilities={project.capabilities}
                contributors={project.credits}
                updateShowAllContributors={this.updateShowAllContributors}
                viewAllOpen={this.state.showViewAllOpen}
              />
            </section>

            {/* START CUSTOM BLOCKS */}
            {project.customBlocks &&
              project.customBlocks?.map((block, i) => {
                switch (block.__typename) {
                  case 'ContentfulCarrouselFullwidth':
                    return (
                      <FullwidthCarousel key={i} visuels={block.visuels} />
                    );

                  case 'ContentfulTexteAvecUnTitre':
                    return (
                      <TextWithTitle
                        key={i}
                        title={block.title}
                        text={block.text.childMarkdownRemark.html}
                      />
                    );

                  case 'ContentfulFullWidthMedia':
                    return (
                      <FullwidthPlaceholderVideo
                        key={i}
                        title={block.title}
                        visuel={block.visuel}
                        videoLink={block.videoLink}
                      />
                    );

                  case 'ContentfulTitreGalerieImageLienDownload':
                    return (
                      <ProjectAssetsGallery
                        key={i}
                        title={block.title}
                        visuels={block.visuels}
                        downloadLink={block.downloadLink}
                        isSlider={block.isSlider || false}
                      />
                    );

                  default:
                    break;
                }
              })}
            {/* END CUSTOM BLOCKS */}

            <ProjectCommon
              isMobile={true}
              capabilities={project.capabilities}
              contributors={project.credits}
              updateShowAllContributors={this.updateShowAllContributors}
              viewAllOpen={this.state.showViewAllOpen}
            />

            {/* VerbatimList */}
            {project.verbatim && <VerbatimList verbatims={project.verbatim} />}

            {/* BackToTop */}
            {!this.state.showViewAllOpen && <BackToTop />}
          </div>
          {/* NavigationBar */}
          <NavigationBar
            previous={navigation.previous}
            next={navigation.next}
          />
        </Layout>
      </>
    );
  }
}

export const query = graphql`
  query contentfulPageProjetTemplate(
    $contentfulId: String!
    $language: String!
    $previous: String
    $next: String
  ) {
    project: contentfulPageProjet(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $language }
    ) {
      id
      node_locale
      title
      client {
        label
      }
      seo {
        metaTitle
        metaDescription
        socialImage {
          file {
            url
          }
        }
        title
        slug
      }
      catchphrase {
        childMarkdownRemark {
          html
        }
      }
      customBlocks {
        __typename
        ... on Node {
          ... on ContentfulCarrouselFullwidth {
            id
            visuels {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
          ... on ContentfulFullWidthMedia {
            id
            visuel {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            title
            videoLink
          }
          ... on ContentfulTexteAvecUnTitre {
            id
            text {
              childMarkdownRemark {
                html
              }
            }
            title
          }
          ... on ContentfulTitreGalerieImageLienDownload {
            id
            title
            isSlider
            downloadLink
            visuels {
              gatsbyImageData(placeholder: BLURRED)
              description
            }
          }
        }
      }
      verbatim {
        author
        verbatim {
          childMarkdownRemark {
            html
          }
        }
        status
      }
      challenge {
        childMarkdownRemark {
          html
        }
      }
      credits {
        name
        job {
          label
          id
        }
      }
      capabilities {
        label
        id
      }
      idea {
        childMarkdownRemark {
          html
        }
      }
      MainPlaceholderVideo {
        visuel {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        backgroundVideo {
          file {
            url
          }
        }
        videoLink
      }
    }
    locales: allContentfulPageProjet(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        seo {
          slug
        }
      }
    }
    navigationPrevious: contentfulPageProjet(
      contentful_id: { eq: $previous }
      node_locale: { eq: $language }
    ) {
      seo {
        slug
      }
      client {
        label
      }
      title
    }
    navigationNext: contentfulPageProjet(
      contentful_id: { eq: $next }
      node_locale: { eq: $language }
    ) {
      seo {
        slug
      }
      client {
        label
      }
      title
    }
  }
`;

export default injectIntl(Project);
