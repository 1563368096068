import React from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function FullwidthCarousel({ visuels }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="block-content">
      <Slider {...settings}>
        {visuels?.map((visuel, i) => {
          console.log(visuel);
          return (
            <GatsbyImage
              key={i}
              as="figure"
              className="is-fullwidth-image"
              image={getImage(visuel)}
              alt={visuel.description || ''}
            />
          );
        })}
      </Slider>
    </section>
  );
}

export default FullwidthCarousel;
