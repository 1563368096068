import React from 'react';
import Verbatim from './verbatim';

function verbatimList({ verbatims }) {
  return (
    <>
      {verbatims?.map((verbatim, i) => (
        <Verbatim key={i} verbatim={verbatim} />
      ))}
    </>
  );
}

export default verbatimList;
