import React from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import AnimateLink from '../animateLink';

const NavigationBar = ({ previous, next }) => {
  return (
    <section className="block-content is-fullwidth bkg-color3 other-cases my-0 py-5">
      <div className="columns is-mobile mx-6 my-5 pb-4">
        <div className="column is-half is-uppercase">
          {previous?.seo?.slug && (
            <>
              <AnimateLink url={previous.seo.slug} cssClassLink="link prev">
                <span className="icon-arrow"></span>{' '}
                <FormattedMessage id="navigation.project.previous" />
              </AnimateLink>
              <p className="subtitle is-4">{previous.client?.label}</p>
              <h1 className="title is-2">{previous.title}</h1>
            </>
          )}
        </div>
        <div className="column is-half has-text-right is-uppercase pb-4">
          {next?.seo?.slug && (
            <>
              <AnimateLink url={next.seo.slug} cssClassLink="link next">
                <FormattedMessage id="navigation.project.next" />{' '}
                <span className="icon-arrow"></span>
              </AnimateLink>
              <p className="subtitle is-4">{next.client?.label}</p>
              <h1 className="title is-2">{next.title}</h1>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default injectIntl(NavigationBar);
