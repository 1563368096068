import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

function Capabilities(props) {
  const { capabilities, isMobile } = props;
  return (
    <article
      className={`block-content capabilities ${isMobile ? ' mt-5 ' : ''}`}
    >
      <h4 className="title is-4 mb-4">
        <FormattedMessage id="project.capabilities" />
      </h4>
      <ul className="is-text-indication">
        {capabilities?.map((capabilitie, i) => (
          <li key={i}>{capabilitie.label}</li>
        ))}
      </ul>
    </article>
  );
}

export default Capabilities;
