import React, { useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PopinVideo from '../popin/popinVideo';

function FullwidthPlaceholderVideo({ title, visuel, videoLink }) {
  const [showPopin, setShowPopin] = useState(false);
  const image = getImage(visuel);

  const handleShowPopin = event => {
    event.preventDefault();
    setShowPopin(!showPopin);
  };

  // Get idVideo Youtube
  if (videoLink) {
    var linkVideo = videoLink;
    linkVideo = linkVideo?.split('https://youtu.be/')[1];
  }

  return (
    <>
      <section className="block-content is-fullwidth">
        {title && <h3 className="title is-3 mb-4">{title}</h3>}

        {image && (
          <figure className="image video">
            <GatsbyImage
              as="figure"
              className="is-fullwidth-image"
              image={image}
              alt={visuel.description || ''}
            />

            {videoLink && (
              <div className="btn-container">
                <a
                  href="#"
                  className="button is-outlined"
                  onClick={e => handleShowPopin(e)}
                >
                  <FormattedMessage id="project.video" />{' '}
                  <span className="icon-play"></span>
                </a>
              </div>
            )}
          </figure>
        )}
      </section>
      <PopinVideo
        show={showPopin}
        handleShowPopin={handleShowPopin}
        idVideo={linkVideo}
      />
    </>
  );
}

export default FullwidthPlaceholderVideo;
